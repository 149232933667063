import React from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import bcrypt from "bcryptjs";

export default class Form extends React.Component {
    copyPassword = async () => {
        let password = document.getElementById("hash").value;
        if (_.get(navigator, "clipboard.writeText")) {
            await navigator.clipboard.writeText(password);
        }
    }

    generateHash = () => {
        let password = document.getElementById("password").value;
        if (password.length > 0) {
            const salt = bcrypt.genSaltSync(10);
            const hash = bcrypt.hashSync(password, salt);
            document.getElementById("hash").value = hash;
        }
    }

    render() {
        return (
            <div className="row p-5 text-center">
                <div className="col-2 text-left">
                    <Link to="/tools" className="btn btn-secondary">Wstecz</Link>
                </div>
                <div className="col-8">
                    <h3>Generator haseł Bcrypt</h3>
                </div>
                <div className="col-2 btn-group">
                    <button className="btn btn-info" onClick={() => this.copyPassword()} >Kopiuj hash</button>
                </div>
                <div className="col-12 mb-5">
                    <hr />
                </div>
                <div className="col-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <button className="btn btn-success" type="button" onClick={() => this.generateHash()}>Generuj hash</button>
                        </div>
                        <input type="text" className="form-control" id="password" />
                        <input type="text" className="form-control" id="hash" readOnly={true} />
                    </div>
                </div>
            </div>
        );
    }
}