import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

export default class ToolsList extends React.Component {
    render() {
        return (
            <div className="m-5 text-center">
                <h3>Lista narzędzi</h3>
                <hr />
                <ul className="list-group">
                    <Link
                        className="list-group-item d-flex flex-grow-5 justify-content-between align-items-center list-group-item-action"
                        to="/passwordGenerator"
                        key={1}
                        title="Generator haseł"
                    >
                        Generator haseł Bcrypt
                    </Link>
                    <Link
                        className="list-group-item d-flex flex-grow-5 justify-content-between align-items-center list-group-item-action"
                        to="/xlsToJson"
                        key={2}
                        title="Konwersja XLS na JSON"
                    >
                        Konwersja XLS na JSON
                    </Link>
                    <Link
                        className="list-group-item d-flex flex-grow-5 justify-content-between align-items-center list-group-item-action"
                        to="/fakerGenerator"
                        key={3}
                        title="Faker Generator"
                    >
                        Faker Generator
                    </Link>
                    <Link
                        className="list-group-item d-flex flex-grow-5 justify-content-between align-items-center list-group-item-action"
                        to="/durationCalculator"
                        key={4}
                        title="Kalkulator czasu zadań"
                    >
                        Kalkulator czasu zadań
                    </Link>
                </ul>
            </div>
        );
    }
}
