import React from 'react';
import { connect } from 'react-redux';
import MediaForm from "../components/mediaForm";
import { getDocument, changeField, saveDocument, clearDocument } from "../actions/mediaForm";

class MediaFormContainer extends React.Component {

    render() {
        return <MediaForm {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        document: store.mediaForm.document,
        errors: store.mediaForm.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        },
        getDocument: (id) => {
            dispatch(getDocument(id));
        },
        saveDocument: (document, history) => {
            dispatch(saveDocument(document, history));
        },
        clearDocument: () => {
            dispatch(clearDocument());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFormContainer);