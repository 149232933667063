import Api from "../services/api";
import _ from 'lodash';
const namespace = "LIST";

export const SET_DOCUMENTS = `SET_DOCUMENTS_${namespace}`;
export const CLEAR_DOCUMENTS = `CLEAR_DOCUMENTS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const CHANGE_OFFSET = `CHANGE_OFFSET_${namespace}`;
export const CHANGE_PAGE = `CHANGE_PAGE_${namespace}`;

export const setDocuments = (documents, total) => {
    return (dispatch) => {
        dispatch({
            type: SET_DOCUMENTS,
            documents,
            total
        });
    }
}

export const setFilters = (filters) => {
    return (dispatch) => {
        dispatch({
            type: SET_FILTERS,
            filters
        });
        dispatch(getDocuments());
    }
}

export const resetFilters = (filters) => {
    return (dispatch) => {
        dispatch({
            type: RESET_FILTERS
        });
        dispatch(getDocuments());
    }
}

export const getDocuments = () => {
    return async (dispatch, getState) => {
        const { list: { filters, offset } } = getState();

        let params = {
            offset: 0,
            offset
        }

        if (!_.isEmpty(filters.name)) {
            params["name"] = filters.name;
        }

        if (!_.isEmpty(filters.tags)) {
            params["tags"] = _.join(filters.tags, ",");
        }

        let res = await Api.get("/application/scripts", params);

        if (res.success) {
            dispatch(setDocuments(res.documents, res.total));
        }
    };
}

export const changeFilter = (name, value) => (dispatch, getState) => {
    const { list: { filters } } = getState();

    let newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    dispatch(setFilters(newFilters));
}

export const changeOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_OFFSET,
        offset
    });
}

export const changePage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PAGE,
        page
    });
}