import produce from "immer";

import { SET_DOCUMENT, CLEAR_DOCUMENT, SET_ERRORS, CLEAR_ERRORS } from "../actions/form";

const initial_state = {
    document: {
        name: "",
        code: ``,
        tags: [],
        description: ""
    },
    errors: {}
}

const reducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_DOCUMENT: {
                draft.document = action.document;
                break;
            }
            case CLEAR_DOCUMENT: {
                draft.document = {
                    name: "",
                    code: ``,
                    tags: [],
                    description: ""
                };
                break;
            }
            case SET_ERRORS: {
                draft.errors = action.errors;
                break;
            }
            case CLEAR_ERRORS: {
                draft.errors = {};
                break;
            }
            default: {
                return draft;
            }
        }
    });
}

export default reducer;