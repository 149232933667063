import { combineReducers } from 'redux';
import listReducer from './list';
import mediaListReducer from './mediaList';
import detailsReducer from './details';
import formReducer from './form';
import mediaFormReducer from './mediaForm';

export default combineReducers({
    list: listReducer,
    details: detailsReducer,
    form: formReducer,
    mediaList: mediaListReducer,
    mediaForm: mediaFormReducer
});