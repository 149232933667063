import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

export default class XLSToJSON extends React.Component {
    state = {
        rawData: [],
        code: "",
        skipHeaders: false,
        format: 'arrays',
        objectKeysFormat: 'headers',
        objectColumns: [],
        errorMessage: ''
    };

    copyJSON = async () => {
        const { code } = this.props;
        if (_.get(navigator, "clipboard.writeText")) {
            await navigator.clipboard.writeText(code);
        }
    };

    convertToJson = () => {
        const { rawData, skipHeaders, format, objectKeysFormat, objectColumns } = this.state;

        if (rawData.length) {
            switch (format) {
                case 'objects': {
                    const headers = rawData[0];
                    const transformed = [];
                    (skipHeaders ? _.tail(rawData) : rawData).forEach((row) => {
                        const result = {};
                        row.forEach((column, columnIndex) => {
                            switch (objectKeysFormat) {
                                case 'indexes': {
                                    result[columnIndex] = column;
                                    break;
                                }
                                case 'custom': {
                                    result[objectColumns[columnIndex]] = column;
                                    break;
                                }
                                case 'headers':
                                default: {
                                    result[headers[columnIndex] || columnIndex] = column;
                                    break;
                                }
                            }
                        });
                        transformed.push(result);
                    });
                    this.setState({ code: JSON.stringify(transformed, null, 2) });
                    break;
                }
                case 'arrays':
                default: {
                    this.setState({ code: JSON.stringify(skipHeaders ? _.tail(rawData) : rawData, null, 2) });
                }
            }
        }
    }

    loadXls = async () => {
        try {
            this.setState({ rawData: [], code: '', errorMessage: '' });
            let input = document.getElementById("input");
            if (input.files.length) {
                const rows = await readXlsxFile(input.files[0]);
                const headers = rows[0];
                const longestRow = _.maxBy(rows, (row) => row.length);
                const objectColumns = [];
                for (let i = 0; i < longestRow.length; i++) {
                    objectColumns.push(headers[i] || i);
                }
                this.setState({ rawData: rows, objectColumns: objectColumns });
            }
        } catch (err) {
            this.setState({ errorMessage: err.message })
        }
    };

    setObjectColumn = (value, index) => {
        const { objectColumns } = this.state;
        const columnsCopy = JSON.parse(JSON.stringify(objectColumns));
        if (!columnsCopy.includes(value) && value.length && value.length < 20) {
            columnsCopy[index] = value;
            this.setState({ objectColumns: columnsCopy });
        }
    }

    render() {
        const { code, skipHeaders, objectKeysFormat, format, rawData, objectColumns, errorMessage } = this.state;
        return (
            <React.Fragment>
                <div className="row p-5 text-center">
                    <div className="col-2 text-left">
                        <Link to="/tools" className="btn btn-secondary">
                            Wstecz
                        </Link>
                    </div>
                    <div className="col-10">
                        <h3>Konwersja XLS na JSON</h3>
                    </div>
                    <div className="col-12 mb-5">
                        <hr />
                    </div>
                </div>
                <div className="row px-5 text-center">
                    <div className="col-2 justify-content-center align-items-center">
                        <input type="file" id="input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={() => this.loadXls()} />
                        {errorMessage && <span className="text-danger mt-2">{JSON.stringify(errorMessage)}</span>}
                    </div>
                    <div className="col-2 justify-content-center align-items-center">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={skipHeaders} onChange={() => this.setState({ skipHeaders: !skipHeaders })} />
                            <label className="form-check-label">
                                Pomiń nagłówki kolumn
                            </label>
                        </div>
                    </div>
                    <div className="col-2 justify-content-center align-items-center">
                        <select className="form-control" value={format} onChange={(e) => this.setState({ format: e.target.value })}>
                            <option value={'arrays'}>Tablica tablic</option>
                            <option value={'objects'}>Tablica obiektów</option>
                        </select>
                    </div>
                    <div className="col-2 justify-content-center align-items-center">
                        <select className="form-control" disabled={format !== 'objects'} value={objectKeysFormat} onChange={(e) => this.setState({ objectKeysFormat: e.target.value })}>
                            <option value={'headers'}>Nagłówki jako klucze</option>
                            <option value={'indexes'}>Indexy kolumn jako klucze</option>
                            <option value={'custom'}>Własne klucze</option>
                        </select>
                    </div>
                    <div className="col-3 justify-content-end align-items-center">
                        <button type="button" className="btn btn-primary" disabled={!rawData.length} onClick={() => { this.convertToJson() }}>Konwertuj</button>
                    </div>
                </div>
                <div className="row p-5 text-center">
                    <div className="col-6">
                        <AceEditor
                            placeholder="JSON"
                            mode="json"
                            theme="monokai"
                            name="code"
                            onLoad={this.onLoad}
                            fontSize={14}
                            readOnly={true}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={code}
                            width="100%"
                            showLineNumbers={true}
                            setOptions={{
                                useWorker: false
                            }}
                        />
                    </div>
                    {objectKeysFormat === 'custom' && <div className="col-6">
                        {objectColumns.map((objColumn, objColumnIndex) => {
                            return <div className="col-12 py-1" key={objColumnIndex}>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={objColumn}
                                    maxLength={20}
                                    minLength={1}
                                    onChange={(e) => this.setObjectColumn(e.target.value, objColumnIndex)} />
                            </div>
                        })}
                    </div>}
                </div>
            </React.Fragment>
        );
    }
}
