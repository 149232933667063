import React from 'react';
import { connect } from 'react-redux';
import { getDocuments, changeFilter, resetFilters, changeOffset, changePage } from "../actions/list";
import List from "../components/list";

class ListContainer extends React.Component {

    render() {
        return <List {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        documents: store.list.documents,
        filters: store.list.filters,
        total: store.list.total,
        page: store.list.page,
        offset: store.list.offset
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocuments: () => {
            dispatch(getDocuments());
        },
        changeFilter: (name, value) => {
            dispatch(changeFilter(name, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        changeOffset: (value) => {
            dispatch(changeOffset(value))
        },
        changePage: (value) => {
            dispatch(changePage(value))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);