import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { faker } from '@faker-js/faker';
import moment from 'moment';
import 'moment-duration-format';

export default class DurationCalculator extends React.Component {
    state = {
        items: [],
        total: '0',
        timeLeft: '8h'
    };

    addItem = () => {
        const { items } = this.state;
        const itemsCopy = JSON.parse(JSON.stringify(items));
        itemsCopy.push({
            duration: {
                days: '0',
                hours: '0',
                minutes: '0'
            },
            formatted: '0d',
            name: faker.git.branch()
        });
        this.setState({ items: itemsCopy });
    };

    deleteItem = (index) => {
        const { items } = this.state;
        const itemsCopy = JSON.parse(JSON.stringify(items));
        itemsCopy.splice(index, 1);
        this.setState({ items: itemsCopy });
        this.recalculate(itemsCopy);
    }

    changeItemName = (value, index) => {
        const { items } = this.state;
        const itemsCopy = JSON.parse(JSON.stringify(items));
        itemsCopy[index].name = value;
        this.setState({ items: itemsCopy });
    }

    changeItemDuration = (value, index, unit) => {
        const { items } = this.state;
        const itemsCopy = JSON.parse(JSON.stringify(items));
        itemsCopy[index].duration = { ...itemsCopy[index].duration, [unit]: value };
        itemsCopy[index].formatted = moment.duration(itemsCopy[index].duration).format();
        this.setState({ items: itemsCopy, total: 0, timeLeft: '8h' });
        this.recalculate(itemsCopy);
    }

    moveItemUp = (index) => {
        const { items } = this.state;
        if (index > 0) {
            const itemsCopy = JSON.parse(JSON.stringify(items));
            [itemsCopy[index - 1], itemsCopy[index]] = [itemsCopy[index], itemsCopy[index - 1]];
            this.setState({ items: itemsCopy });
        }
    }

    moveItemDown = (index) => {
        const { items } = this.state;
        if (index < items.length - 1) {
            const itemsCopy = JSON.parse(JSON.stringify(items));
            [itemsCopy[index], itemsCopy[index + 1]] = [itemsCopy[index + 1], itemsCopy[index]];
            this.setState({ items: itemsCopy });
        }
    }

    recalculate = (items) => {
        let total = moment.duration(0);
        items.forEach((item) => {
            total.add(moment.duration(item.duration));
        });
        let left = moment.duration(8, 'hours').subtract(total);
        this.setState({ total: total.format(), timeLeft: left.format() });
    }

    render() {
        const { total, target, items, timeLeft } = this.state;
        return (
            <React.Fragment>
                <div className="row p-5 text-center">
                    <div className="col-2 text-left">
                        <Link to="/tools" className="btn btn-secondary">
                            Wstecz
                        </Link>
                    </div>
                    <div className="col-10">
                        <h3>Kalkulator czasu zadań</h3>
                    </div>
                    <div className="col-12 mb-5">
                        <hr />
                    </div>
                </div>
                <div className="row px-5">
                    <div className="col-9">
                        <h6>Ilość zadań: <b>{items.length}</b></h6>
                        <h6>Łączny czas zadań: <b>{total}</b></h6>
                        <h6>Pozostały czas do 8h: <b>{timeLeft}</b></h6>
                    </div>
                    <div className="col-3 text-right">
                        <button type="button" className="btn btn-primary" onClick={() => { this.addItem() }}>Dodaj zadanie</button>
                    </div>
                </div>
                {items.map((item, itemIndex) => {
                    return <div className="row px-5 py-2 d-flex align-items-center" key={itemIndex}>
                        <div className="col-2">
                            <label>Nazwa zadania</label>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                value={item.name}
                                maxLength={20}
                                minLength={1}
                                onChange={(e) => this.changeItemName(e.target.value, itemIndex)} />
                        </div>
                        <div className="col-2">
                            <label>Ilość dni</label>
                            <input
                                className="form-control form-control-sm"
                                type="number"
                                value={item.duration.days}
                                min={0}
                                onChange={(e) => this.changeItemDuration(e.target.value, itemIndex, 'days')} />
                        </div>
                        <div className="col-2">
                            <label>Ilość godzin</label>
                            <input
                                className="form-control form-control-sm"
                                type="number"
                                value={item.duration.hours}
                                min={0}
                                onChange={(e) => this.changeItemDuration(e.target.value, itemIndex, 'hours')} />
                        </div>
                        <div className="col-2">
                            <label>Ilość minut</label>
                            <input
                                className="form-control form-control-sm"
                                type="number"
                                value={item.duration.minutes}
                                min={0}
                                onChange={(e) => this.changeItemDuration(e.target.value, itemIndex, 'minutes')} />
                        </div>
                        <div className="col-4 btn-group btn-group-sm align-self-end">
                            <button type="button" className="btn btn-sm btn-secondary" disabled={itemIndex === 0} onClick={() => { this.moveItemUp(itemIndex) }}>Przesuń do góry</button>
                            <button type="button" className="btn btn-sm btn-secondary" disabled={itemIndex === items.length - 1} onClick={() => { this.moveItemDown(itemIndex) }}>Przesuń w dół</button>
                            <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.deleteItem(itemIndex) }}>Usuń</button>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                })}
            </React.Fragment>
        );
    }
}
