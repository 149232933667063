import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import AceEditor from "react-ace";
import { faker } from '@faker-js/faker';
import { fakerConfig, fakerSections } from "../assets/faker-config"
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

export default class FakerGenerator extends React.Component {
    state = {
        fields: [],
        amount: 1,
        code: ''
    };

    addField = () => {
        const { fields } = this.state;
        const fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy.push({
            type: _.sample(Object.keys(fakerConfig)),
            name: `${faker.database.column()}_${faker.number.int({ min: 100, max: 999 })}`
        });
        this.setState({ fields: fieldsCopy });
    };

    deleteField = (index) => {
        const { fields } = this.state;
        const fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy.splice(index, 1);
        this.setState({ fields: fieldsCopy });
    }

    changeFieldName = (value, index) => {
        const { fields } = this.state;
        const fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy[index].name = value;
        this.setState({ fields: fieldsCopy });
    }

    changeFieldType = (value, index) => {
        const { fields } = this.state;
        const fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy[index].type = value;
        this.setState({ fields: fieldsCopy });
    }

    moveFieldUp = (index) => {
        const { fields } = this.state;
        if (index > 0) {
            const fieldsCopy = JSON.parse(JSON.stringify(fields));
            [fieldsCopy[index - 1], fieldsCopy[index]] = [fieldsCopy[index], fieldsCopy[index - 1]];
            this.setState({ fields: fieldsCopy });
        }
    }

    moveFieldDown = (index) => {
        const { fields } = this.state;
        if (index < fields.length - 1) {
            const fieldsCopy = JSON.parse(JSON.stringify(fields));
            [fieldsCopy[index], fieldsCopy[index + 1]] = [fieldsCopy[index + 1], fieldsCopy[index]];
            this.setState({ fields: fieldsCopy });
        }
    }

    renderSection = (section) => {
        const elements = Object.entries(fakerConfig).filter(([type, config]) => {
            return config.section === section;
        });
        console.log(elements, section);
        return <optgroup label={section} key={section}>
            {elements.map(([type, config]) => (
                <option key={type} value={type} title={config.description}>{type}</option>
            ))}
        </optgroup>
    }

    generateObject = () => {
        const { fields } = this.state;
        const result = {};
        fields.forEach((field) => {
            if (fakerConfig[field.type] && _.isFunction(fakerConfig[field.type].fn)) {
                result[field.name] = fakerConfig[field.type].fn();
            } else {
                result[field.name] = null;
            }
        });
        this.setState({ code: JSON.stringify(result, null, 2) });
    }

    render() {
        const { code, fields } = this.state;
        return (
            <React.Fragment>
                <div className="row p-5 text-center">
                    <div className="col-2 text-left">
                        <Link to="/tools" className="btn btn-secondary">
                            Wstecz
                        </Link>
                    </div>
                    <div className="col-10">
                        <h3>Faker Generator</h3>
                    </div>
                    <div className="col-12 mb-5">
                        <hr />
                    </div>
                </div>
                <div className="row p-5 text-center">
                    <div className="col-6">
                        <AceEditor
                            placeholder="Kod"
                            mode="javascript"
                            theme="monokai"
                            name="code"
                            onLoad={this.onLoad}
                            fontSize={14}
                            readOnly={true}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={code}
                            width="100%"
                            showLineNumbers={true}
                        />
                    </div>
                    <div className="col-6">
                        <div className="row py-2">
                            <div className="col-2">
                                <button type="button" className="btn btn-primary" onClick={() => { this.addField() }}>Dodaj pole</button>
                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-success" onClick={() => { this.generateObject() }}>Generuj</button>
                            </div>
                        </div>
                        {fields.map((field, fieldIndex) => {
                            return <div className="row py-1 d-flex align-items-center" key={fieldIndex}>
                                <div className="col-4">
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        value={field.name}
                                        maxLength={20}
                                        minLength={1}
                                        onChange={(e) => this.changeFieldName(e.target.value, fieldIndex)} />
                                </div>
                                <div className="col-4">
                                    <select className="form-control form-control-sm" value={field.type} onChange={(e) => this.changeFieldType(e.target.value, fieldIndex)}>
                                        {Object.values(fakerSections).map((section) => this.renderSection(section))}
                                    </select>
                                </div>
                                <div className="col-4 btn-group btn-group-sm">
                                    <button type="button" className="btn btn-sm btn-secondary" disabled={fieldIndex === 0} onClick={() => { this.moveFieldUp(fieldIndex) }}>Przesuń do góry</button>
                                    <button type="button" className="btn btn-sm btn-secondary" disabled={fieldIndex === fields.length - 1} onClick={() => { this.moveFieldDown(fieldIndex) }}>Przesuń w dół</button>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.deleteField(fieldIndex) }}>Usuń</button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
