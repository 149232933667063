import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ListContainer from "./containers/list";
import FormContainer from "./containers/form";
import DetailsContainer from "./containers/details";
import MediaListContainer from "./containers/mediaList";
import MediaFormContainer from "./containers/mediaForm";
import MediaDetailsContainer from "./containers/mediaDetails";
import ToolsList from './components/toolsList';
import PasswordGenerator from "./components/passwordGenerator";
import XLSToJSON from './components/xlsToJson';
import FakerGenerator from './components/fakerGenerator';
import DurationCalculator from './components/durationCalculator';

export default class App extends React.Component {

  onKeyChange = (keyCode) => {
    let value = this.refs.keyInput.value;
    if (keyCode === 13 && value) {
      localStorage.setItem("appKey", value);
      this.forceUpdate();
    }
  }

  render() {
    let appKey = localStorage.getItem("appKey");

    if (!appKey) {
      return (
        <div className="row m-5 text-center">
          <div className="col-12 form-group">
            <label>Klucz zabezpieczeń</label>
            <input className="form-control" type="text" name="appKey" placeholder="Wpisz klucz i nacisnij Enter" onKeyDown={(e) => { this.onKeyChange(e.keyCode) }} ref="keyInput" />
          </div>
        </div>

      )
    }

    return (
      <Router>
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">Skrypty</Link>
              </li>
              <li className="nav-item">
                <Link to="/media" className="nav-link">Media</Link>
              </li>
              <li className="nav-item">
                <Link to="/tools" className="nav-link">Narzędzia</Link>
              </li>
            </ul>
          </div>
        </nav>
        <Route exact path="/" component={ListContainer} />
        <Route exact path="/media" component={MediaListContainer} />
        <Route exact path="/mediaForm/:id?" component={MediaFormContainer} />
        <Route exact path="/mediaDetails/:id" component={MediaDetailsContainer} />
        <Route exact path="/form/:id?" component={FormContainer} />
        <Route exact path="/details/:id" component={DetailsContainer} />
        <Route exact path="/tools" component={ToolsList} />
        <Route exact path="/passwordGenerator" component={PasswordGenerator} />
        <Route exact path="/xlsToJson" component={XLSToJSON} />
        <Route exact path="/fakerGenerator" component={FakerGenerator} />
        <Route exact path="/durationCalculator" component={DurationCalculator} />
      </Router>
    );
  }

}
