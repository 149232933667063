const icons = [
    "aac",
    "ai",
    "aiff",
    "avi",
    "bmp",
    "c",
    "cpp",
    "css",
    "csv",
    "dat",
    "DirContents",
    "dmg",
    "doc",
    "dotx",
    "dwg",
    "dxf",
    "eps",
    "exe",
    "file",
    "flv",
    "gif",
    "h",
    "hpp",
    "html",
    "ics",
    "iso",
    "java",
    "jpg",
    "jpeg",
    "js",
    "key",
    "less",
    "mid",
    "mp3",
    "mp4",
    "mpg",
    "odf",
    "ods",
    "odt",
    "otp",
    "ots",
    "ott",
    "pdf",
    "php",
    "png",
    "ppt",
    "psd",
    "py",
    "qt",
    "rar",
    "rb",
    "rtf",
    "sass",
    "scss",
    "sql",
    "tga",
    "tgz",
    "tiff",
    "txt",
    "wav",
    "xls",
    "xlsx",
    "xml",
    "yml",
    "zip",
    "_page"
];

export default icons;