import React from 'react';
import { connect } from 'react-redux';
import MediaDetails from "../components/mediaDetails";
import { getDocument, clearDocument, deleteFile, downloadFile } from "../actions/mediaForm";

class MediaDetailsContainer extends React.Component {

    render() {
        return <MediaDetails {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        document: store.mediaForm.document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (id) => {
            dispatch(getDocument(id));
        },
        clearDocument: () => {
            dispatch(clearDocument());
        },
        deleteFile: (history) => {
            dispatch(deleteFile(history));
        },
        downloadFile: (name) => {
            dispatch(downloadFile(name));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetailsContainer);