import Api from "../services/api";
const namespace = "FORM";

export const SET_DOCUMENT = `SET_DOCUMENT_${namespace}`;
export const CLEAR_DOCUMENT = `CLEAR_DOCUMENT_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

export const setDocument = (document) => {
    return (dispatch) => {
        dispatch({
            type: SET_DOCUMENT,
            document: document
        });
    }
}

export const setErrors = (errors) => {
    return (dispatch) => {
        dispatch({
            type: SET_ERRORS,
            errors: errors
        });
    }
}

export const clearErrors = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ERRORS
        });
    }
}

export const clearDocument = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DOCUMENT
        });
    }
}

export const getDocument = (id) => {
    return async (dispatch, getState) => {

        let res = await Api.get(`/application/scripts/${id}`);

        if (res.success) {
            dispatch(setDocument(res.document));
        }
    };
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { form: { document } } = getState();

    let newDocument = JSON.parse(JSON.stringify(document));
    newDocument[name] = value;
    dispatch(setDocument(newDocument));
}

export const saveDocument = (history) => async (dispatch, getState) => {
    const { form: { document } } = getState();
    dispatch(clearErrors());

    let res = null;

    if (document._id) {
        res = await Api.put(`/application/scripts/${document._id}`, document);
    } else {
        res = await Api.post("/application/scripts", document);
    }

    if (res && res.success) {
        history.push("/");
    } else if (res.err) {
        dispatch(setErrors(res.err));
    }
}

export const deleteScript = (history) => async (dispatch, getState) => {
    const { form: { document } } = getState();

    let res = await Api.delete(`/application/scripts/${document._id}`);

    if (res.success) {
        history.push("/");
    }
}