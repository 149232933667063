import React from 'react';
import { connect } from 'react-redux';
import Form from "../components/form";
import { getDocument, changeField, saveDocument, clearDocument } from "../actions/form";

class FormContainer extends React.Component {

    render() {
        return <Form {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        document: store.form.document,
        errors: store.form.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        },
        getDocument: (id) => {
            dispatch(getDocument(id));
        },
        saveDocument: (history) => {
            dispatch(saveDocument(history));
        },
        clearDocument: () => {
            dispatch(clearDocument());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);