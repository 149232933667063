import produce from "immer";

import { SET_DOCUMENTS, CLEAR_DOCUMENTS, SET_FILTERS, RESET_FILTERS, CHANGE_OFFSET, CHANGE_PAGE } from "../actions/list";

const initial_state = {
    documents: [],
    total: 0,
    page: 1,
    offset: 0,
    filters: {
        name: "",
        tags: []
    }
}

const reducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_DOCUMENTS: {
                draft.documents = action.documents;
                draft.total = action.total;
                break;
            }
            case CLEAR_DOCUMENTS: {
                draft.documents = [];
                draft.total = 0;
                break;
            }
            case SET_FILTERS: {
                draft.filters = action.filters;
                break;
            }
            case RESET_FILTERS: {
                draft.filters = {
                    name: "",
                    tags: []
                };
                break;
            }
            case CHANGE_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            case CHANGE_PAGE: {
                draft.page = action.page;
                break;
            }
            default: {
                return draft;
            }
        }
    });
}

export default reducer;