import React from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

export default class MediaForm extends React.Component {

    componentWillMount() {
        const { getDocument, match } = this.props;
        if (_.get(match, "params.id")) {
            getDocument(_.get(match, "params.id"));
        }
    }

    changeField = (name, value) => {
        const { changeField } = this.props;
        changeField(name, value);
    }

    renderTags = () => {
        const { document } = this.props;

        if (_.isArray(document.tags)) {
            return document.tags.map((tag, index) => {
                return <span className="badge badge-secondary mr-1 with-pointer" key={`tag${index}`} title="Kliknij aby usunąć" onClick={() => this.deleteTag(tag)}>{tag}</span>
            });
        } else {
            return <p>Brak tagów</p>
        }
    }

    changeTags = (keyCode) => {
        const { document, changeField } = this.props;
        let value = this.refs.tags.value;
        let tags = JSON.parse(JSON.stringify(document.tags));
        if (keyCode === 13 && !_.includes(document.tags, value) && !_.isEmpty(value)) {
            tags.push(value);
            changeField("tags", tags);
            this.refs.tags.value = "";
        }
    }

    deleteTag = (tag) => {
        const { document, changeField } = this.props;
        let tags = _.xor(JSON.parse(JSON.stringify(document.tags)), [tag]);
        changeField("tags", tags);
    }

    saveDocument = () => {
        const { saveDocument, history, document } = this.props;
        let file = this.refs["mediaFile"].files[0];
        saveDocument(Object.assign({}, document, { file: file }), history);
    }

    render() {
        const { document, errors } = this.props;
        const tags = this.renderTags();

        return (
            <div className="row p-5 text-center">
                <div className="col-2 text-left">
                    <Link to="/media" className="btn btn-secondary">Wstecz</Link>
                </div>
                <div className="col-10">
                    <h3>Dodaj skrypt</h3>
                </div>
                <div className="col-12 mb-5">
                    <hr />
                </div>
                <div className="col-12">
                    <form className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Nazwa skryptu</label>
                                <input type="text" className="form-control" ref="name" placeholder="Wpisz nazwę" value={document.name} onChange={(e) => { this.changeField('name', e.target.value) }} />
                                {_.get(errors, "name") && <p className="text-danger">{errors.name}</p>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Opis skryptu</label>
                                <textarea className="form-control" ref="description" placeholder="Wpisz opis" rows="20" cols="3" value={document.description} onChange={(e) => { this.changeField('description', e.target.value) }} />
                                {_.get(errors, "description") && <p className="text-danger">{errors.description}</p>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Dodaj plik</label>
                                <input type="file" className="form-control" multiple={false} ref="mediaFile" />
                                {document.file && <p className="mt-2">Aktualny plik: {document.file}</p>}
                            </div>
                            {_.get(errors, "file") && <p className="text-danger">{errors.file}</p>}
                        </div>
                        <div className="col-12">
                            {tags}
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Tagi</label>
                                <input type="text" className="form-control" ref="tags" placeholder="Wpisz nazwę tagu i nacisnij Enter" onKeyDown={(e) => { this.changeTags(e.keyCode) }} />
                                {_.get(errors, "tags") && <p className="text-danger">{errors.tags}</p>}
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-success" type="button" onClick={() => this.saveDocument()}>Zapisz</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }
}