import { fakerPL } from '@faker-js/faker';

export const fakerSections = {
    STRING: 'STRING',
    COLOR: 'COLOR',
    DB: 'DB',
    DATATYPE: 'DATATYPE',
    DATE: 'DATE',
    COMMERCE: 'COMMERCE',
    PERSON: 'PERSON',
    FINANCE: 'FINANCE',
    IMAGE: 'IMAGE',
    INTERNET: 'INTERNET',
    LOCATION: 'LOCATION',
    SCIENCE: 'SCIENCE'
}

export const fakerConfig = {
    //STRING
    alpha: {
        section: fakerSections.STRING,
        description: 'Generating a string consisting of letters in the English alphabet.',
        fn: () => {
            return fakerPL.string.alpha({
                length: { min: 5, max: 20 }
            });
        }
    },
    alphanumeric: {
        section: fakerSections.STRING,
        description: 'Generating a string consisting of alpha characters and digits.',
        fn: () => {
            return fakerPL.string.alphanumeric({
                length: { min: 5, max: 20 }
            });
        }
    },
    loremIpsum: {
        section: fakerSections.STRING,
        description: 'Generating 3 lorem ipsum paragraphs.',
        fn: () => {
            return fakerPL.lorem.paragraphs();
        }
    },
    words: {
        section: fakerSections.STRING,
        description: 'Returns a string containing a number of space separated random words.',
        fn: () => {
            return fakerPL.word.words({ count: { min: 5, max: 10 } });
        }
    },
    binary: {
        section: fakerSections.STRING,
        description: 'Returns a binary string.',
        fn: () => {
            return fakerPL.string.binary({
                length: { min: 5, max: 20 }
            });
        }
    },
    hexadecimal: {
        section: fakerSections.STRING,
        description: 'Returns a hexadecimal string.',
        fn: () => {
            return fakerPL.string.hexadecimal({
                length: { min: 5, max: 20 }
            });
        }
    },
    nanoid: {
        section: fakerSections.STRING,
        description: 'Generates a Nano ID.',
        fn: () => {
            return fakerPL.string.nanoid()
        }
    },
    numeric: {
        section: fakerSections.STRING,
        description: 'Generates a given length string of digits.',
        fn: () => {
            return fakerPL.string.numeric({
                length: { min: 5, max: 20 }
            });
        }
    },
    uuid: {
        section: fakerSections.STRING,
        description: 'Returns a UUID v4 (Universally Unique Identifier).',
        fn: () => {
            return fakerPL.string.uuid();
        }
    },
    //COLOR
    hexColor: {
        section: fakerSections.COLOR,
        description: 'Generates a random css hex color code',
        fn: () => {
            return fakerPL.internet.color()
        }
    },
    rgbColor: {
        section: fakerSections.COLOR,
        description: 'Returns an RGB color.',
        fn: () => {
            return fakerPL.color.rgb({ format: 'css' })
        }
    },
    human: {
        section: fakerSections.COLOR,
        description: 'Returns a random human-readable color name.',
        fn: () => {
            return fakerPL.color.human()
        }
    },
    //DB
    objectId: {
        section: fakerSections.DB,
        description: 'Returns MongoDb ObjectId string',
        fn: () => {
            return fakerPL.database.mongodbObjectId()
        }
    },
    //DATATYPE
    bool: {
        section: fakerSections.DATATYPE,
        description: 'Returns the boolean value true or false.',
        fn: () => {
            return fakerPL.datatype.boolean();
        }
    },
    nullish: {
        section: fakerSections.DATATYPE,
        description: 'Returns null.',
        fn: () => {
            return null;
        }
    },
    empty_object: {
        section: fakerSections.DATATYPE,
        description: 'Returns empty object',
        fn: () => {
            return {};
        }
    },
    empty_array: {
        section: fakerSections.DATATYPE,
        description: 'Returns empty array',
        fn: () => {
            return [];
        }
    },
    //DATE
    pastDate: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the past.',
        fn: () => {
            return fakerPL.date.past();
        }
    },
    pastTimestamp: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the past as timestamp.',
        fn: () => {
            return fakerPL.date.past().getTime();
        }
    },
    recentDate: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the recent past.',
        fn: () => {
            return fakerPL.date.recent();
        }
    },
    recentDate: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the recent past as timestamp.',
        fn: () => {
            return fakerPL.date.recent().getTime();
        }
    },
    nowDate: {
        section: fakerSections.DATE,
        description: 'Returns current date',
        fn: () => {
            return fakerPL.date.past();
        }
    },
    nowTimestamp: {
        section: fakerSections.DATE,
        description: 'Returns current date as timestamp',
        fn: () => {
            return fakerPL.date.past().getTime();
        }
    },
    soonDate: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the near future.',
        fn: () => {
            return fakerPL.date.soon();
        }
    },
    soonTimestamp: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the near future  as timestamp.',
        fn: () => {
            return fakerPL.date.soon().getTime();
        }
    },
    futureDate: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the future.',
        fn: () => {
            return fakerPL.date.future();
        }
    },
    futureTimestamp: {
        section: fakerSections.DATE,
        description: 'Generates a random date in the future as timestamp.',
        fn: () => {
            return fakerPL.date.future().getTime();
        }
    },
    month: {
        section: fakerSections.DATE,
        description: 'Returns a random name of a month.',
        fn: () => {
            return fakerPL.date.month();
        }
    },
    weekday: {
        section: fakerSections.DATE,
        description: 'Returns a random day of the week.',
        fn: () => {
            return fakerPL.date.weekday();
        }
    },
    birthdate: {
        section: fakerSections.DATE,
        description: 'Returns a random birthdate.',
        fn: () => {
            return fakerPL.date.birthdate();
        }
    },
    birthdateTimestamp: {
        section: fakerSections.DATE,
        description: 'Returns a random birthdate as timestamp.',
        fn: () => {
            return fakerPL.date.birthdate().getTime();
        }
    },
    //COMMERCE
    productName: {
        section: fakerSections.COMMERCE,
        description: 'Generates a random descriptive product name.',
        fn: () => {
            return fakerPL.commerce.productName();
        }
    },
    productDescription: {
        section: fakerSections.COMMERCE,
        description: 'Returns a product description.',
        fn: () => {
            return fakerPL.commerce.productDescription();
        }
    },
    companyName: {
        section: fakerSections.COMMERCE,
        description: 'Returns a random company name.',
        fn: () => {
            return fakerPL.company.name();
        }
    },
    //PERSON
    firstName: {
        section: fakerSections.PERSON,
        description: 'Returns a random first name.',
        fn: () => {
            return fakerPL.person.firstName();
        }
    },
    fullName: {
        section: fakerSections.PERSON,
        description: 'Returns a random full name.',
        fn: () => {
            return fakerPL.person.fullName();
        }
    },
    lastName: {
        section: fakerSections.PERSON,
        description: 'Returns a random last name.',
        fn: () => {
            return fakerPL.person.lastName();
        }
    },
    jobTitle: {
        section: fakerSections.PERSON,
        description: 'Returns a random job title.',
        fn: () => {
            return fakerPL.person.jobTitle();
        }
    },
    jobType: {
        section: fakerSections.PERSON,
        description: 'Generates a random job type.',
        fn: () => {
            return fakerPL.person.jobType();
        }
    },
    sex: {
        section: fakerSections.PERSON,
        description: 'Generates a random sex.',
        fn: () => {
            return fakerPL.person.sex();
        }
    },
    bio: {
        section: fakerSections.PERSON,
        description: 'Returns a random short biography.',
        fn: () => {
            return fakerPL.person.bio();
        }
    },
    phone: {
        section: fakerSections.PERSON,
        description: 'Returns a random phone number.',
        fn: () => {
            return fakerPL.number.int(9);
        }
    },
    //FINANCE
    price_string: {
        section: fakerSections.FINANCE,
        description: 'Generates a random price (0.1 - 1000) as string.',
        fn: () => {
            return fakerPL.finance.amount({ min: 0.1, max: 1000 });
        }
    },
    price_number: {
        section: fakerSections.FINANCE,
        description: 'Generates a random price (0.1 - 1000) as number.',
        fn: () => {
            return parseFloat(fakerPL.finance.amount({ min: 0.1, max: 1000 }));
        }
    },
    accountNumber: {
        section: fakerSections.FINANCE,
        description: 'Generates a random account number.',
        fn: () => {
            return fakerPL.finance.accountNumber();
        }
    },
    currencyCode: {
        section: fakerSections.FINANCE,
        description: 'Returns a random currency code. (The short text/abbreviation for the currency (e.g. US Dollar -> USD))',
        fn: () => {
            return fakerPL.finance.currencyCode();
        }
    },
    creditCardNumber: {
        section: fakerSections.FINANCE,
        description: 'Generates a random credit card number.',
        fn: () => {
            return fakerPL.finance.creditCardNumber({ issuer: 'visa' });
        }
    },
    pin: {
        section: fakerSections.FINANCE,
        description: 'Generates a random PIN number.',
        fn: () => {
            return fakerPL.finance.pin();
        }
    },
    invoiceNumber: {
        section: fakerSections.FINANCE,
        description: 'Generates a random invoice number.',
        fn: () => {
            return `${fakerPL.number.int({ min: 2010, max: 2024 })}/INV/${fakerPL.number.int({ min: 1, max: 999 })}`;
        }
    },
    //IMAGE
    url: {
        section: fakerSections.IMAGE,
        description: 'Generates a random image url.',
        fn: () => {
            return fakerPL.image.url();
        }
    },
    dataUri: {
        section: fakerSections.IMAGE,
        description: 'Generates a random data uri containing an URL-encoded SVG image or a Base64-encoded SVG image.',
        fn: () => {
            return fakerPL.image.dataUri({ type: 'svg-base64' });
        }
    },
    avatar: {
        section: fakerSections.IMAGE,
        description: 'Generates a random avatar image url.',
        fn: () => {
            return fakerPL.image.avatar();
        }
    },
    //INTERNET
    email: {
        section: fakerSections.INTERNET,
        description: 'Generates an email address using the given persons name as base.',
        fn: () => {
            return fakerPL.internet.email();
        }
    },
    userName: {
        section: fakerSections.INTERNET,
        description: 'Generates a username',
        fn: () => {
            return fakerPL.internet.userName();
        }
    },
    domainName: {
        section: fakerSections.INTERNET,
        description: 'Generates a random domain name.',
        fn: () => {
            return fakerPL.internet.domainName();
        }
    },
    url: {
        section: fakerSections.INTERNET,
        description: 'Generates a random http(s) url.',
        fn: () => {
            return fakerPL.internet.url();
        }
    },
    password: {
        section: fakerSections.INTERNET,
        description: 'Generates a random password.',
        fn: () => {
            return fakerPL.internet.password();
        }
    },
    //LOCATION
    city: {
        section: fakerSections.LOCATION,
        description: 'Generates a random localized city name.',
        fn: () => {
            return fakerPL.location.city();
        }
    },
    country: {
        section: fakerSections.LOCATION,
        description: 'Returns a random country name.',
        fn: () => {
            return fakerPL.location.country();
        }
    },
    countryCode: {
        section: fakerSections.LOCATION,
        description: 'Returns a random ISO_3166-1 country code.',
        fn: () => {
            return fakerPL.location.countryCode();
        }
    },
    county: {
        section: fakerSections.LOCATION,
        description: 'Returns a random localized county, or other equivalent second-level administrative entity for the locales country such as a district or department.',
        fn: () => {
            return fakerPL.location.county();
        }
    },
    latitude: {
        section: fakerSections.LOCATION,
        description: 'Generates a random latitude.',
        fn: () => {
            return fakerPL.location.latitude();
        }
    },
    longitude: {
        section: fakerSections.LOCATION,
        description: 'Generates a random longitude.',
        fn: () => {
            return fakerPL.location.longitude();
        }
    },
    longitude: {
        section: fakerSections.LOCATION,
        description: 'Generates a random longitude.',
        fn: () => {
            return fakerPL.location.longitude();
        }
    },
    state: {
        section: fakerSections.LOCATION,
        description: 'Returns a random localized state, or other equivalent first-level administrative entity for the locales country such as a province or region.',
        fn: () => {
            return fakerPL.location.state();
        }
    },
    street: {
        section: fakerSections.LOCATION,
        description: 'Generates a random localized street name.',
        fn: () => {
            return fakerPL.location.street();
        }
    },
    streetAddress: {
        section: fakerSections.LOCATION,
        description: 'Generates a random localized street address.',
        fn: () => {
            return fakerPL.location.streetAddress();
        }
    },
    zipCode: {
        section: fakerSections.LOCATION,
        description: 'Generates random zip code from specified format. If format is not specified, the locales zip format is used.',
        fn: () => {
            return fakerPL.location.zipCode();
        }
    },
    fullAddress: {
        section: fakerSections.LOCATION,
        description: 'Returns full address',
        fn: () => {
            return `${fakerPL.location.zipCode()} ${fakerPL.location.city()}, ${fakerPL.location.streetAddress()}`;
        }
    },
    //SCIENCE
    unit: {
        section: fakerSections.SCIENCE,
        description: 'Returns a random scientific unit.',
        fn: () => {
            return fakerPL.science.unit().symbol;
        }
    },
}