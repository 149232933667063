import produce from "immer";

const initial_state = {
    
}

const reducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            default: {
                return draft;
            }
        }
    });
}

export default reducer;