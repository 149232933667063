import React from 'react';
import { connect } from 'react-redux';
import Details from "../components/details";
import { getDocument, clearDocument, deleteScript } from "../actions/form";

class DetailsContainer extends React.Component {

    render() {
        return <Details {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        document: store.form.document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (id) => {
            dispatch(getDocument(id));
        },
        clearDocument: () => {
            dispatch(clearDocument());
        },
        deleteScript: (history) => {
            dispatch(deleteScript(history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsContainer);