import React from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import icons from "../assets/icons";

export default class MediaForm extends React.Component {

    componentDidMount() {
        const { getDocument, match } = this.props;
        if (_.get(match, "params.id")) {
            getDocument(_.get(match, "params.id"));
        }
    }

    renderTags = () => {
        const { document } = this.props;

        if (_.isArray(document.tags)) {
            return document.tags.map((tag, index) => {
                return <span className="badge badge-secondary mr-1" key={`tag${index}`}>{tag}</span>
            });
        } else {
            return <p>Brak tagów</p>
        }
    }

    deleteFile = () => {
        const { deleteFile, history } = this.props;
        document.getElementById("dismiss-button").click();
        deleteFile(history);
    }

    renderDeleteModal = () => {
        return (
            <div className="modal" id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Czy chcesz usunąć?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="dismiss-button" data-dismiss="modal">Zamknij</button>
                            <button type="button" className="btn btn-danger" onClick={() => { this.deleteFile() }}>Usuń</button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    downloadFile() {
        const { document, downloadFile } = this.props;
        let fileName = _.replace(document.file, "media/", "")
        downloadFile(fileName);
    }

    getAvatar = () => {
        const { document } = this.props;
        if (_.get(document, "file")) {
            let ext = _.last(document.file.split('.'));
            if (icons.indexOf(ext) > -1) {
                return <img className="d-flex align-self-center" src={`/icons/${ext}.png`} alt="Plik" title={document.file} width="100px" height="100px" />
            } else {
                return <img className="d-flex align-self-center" src="/icons/file.png" alt="Plik" title={document.file} width="100px" height="100px" />
            }
        }
        return null;
    }

    render() {
        const { document } = this.props;

        if (!document) {
            return null;
        }
        const deleteModal = this.renderDeleteModal();
        const tags = this.renderTags();
        const avatar = this.getAvatar();
        return (
            <div className="row p-5 text-center">
                {deleteModal}
                <div className="col-2 text-left">
                    <Link to="/media" className="btn btn-secondary">Wstecz</Link>
                </div>
                <div className="col-8">
                    <h3>{document.name}</h3>
                </div>
                <div className="col-2 btn-group">
                    <Link to={`/mediaForm/${document._id}`} className="btn btn-primary">Edytuj</Link>
                    <button className="btn btn-info" onClick={() => this.downloadFile()} >Pobierz plik</button>
                    <button className="btn btn-danger" data-toggle="modal" data-target="#deleteModal">Usuń</button>
                </div>
                <div className="col-12 mb-5">
                    <hr />
                </div>
                <div className="col-12">
                    <div className="media">
                        {avatar}
                        <div className="media-body">
                            <h5 className="mt-0">{document.file}</h5>
                            <p className="mt-3">{document.description}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <label><b>Tagi</b></label><br />
                    {tags}
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }

}