import _ from 'lodash';

// const url = 'http://localhost:3001';
// const url = 'http://10.1.1.115:3001';
const url = 'https://api-script-repo.overcloud.usermd.net';

class Api {

    put(route, params, isFormData = false) {
        return this.api(route, params, 'PUT', isFormData);
    }

    delete(route, params) {
        return this.api(route, params, 'DELETE');
    }

    post(route, params, isFormData = false) {
        return this.api(route, params, 'POST', isFormData);
    }

    get(route, params, fileRes = false) {
        if (params) {
            params = _.zipWith(_.keys(params), _.values(params), (key, val) => {
                return `${key}=${val}`
            });
            route += '?' + _.join(params, '&');
        }
        return this.api(route, null, 'GET', false, fileRes);
    }

    setFormData(data) {
        let form = new FormData();
        _.each(data, (value, key) => {
            if (value instanceof File) {
                form.append(key, value, value.name);
            }
            if (!_.isEmpty(value)) {
                if (_.isObject(value)) {
                    _.each((value), (v, k) => {
                        form.append(`${key}[${k}]`, v);
                    });
                }
                else if (_.isArray(value)) {
                    _.each((value), (v) => {
                        if (v instanceof File) {
                            form.append(`${key}[]`, v, v.name);
                        } else {
                            form.append(`${key}[]`, v);
                        }
                    });
                } else {
                    form.append(key, value)
                }
            }
        });
        return form;
    }

    api(route, params, method, isFormData = false, fileRes = false) {
        let appKey = localStorage.getItem('appKey');
        let options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'authorization': appKey,
                'Accept': 'application/json'
            }
        }

        if (params && Object.keys(params).length > 0) {
            if (isFormData) {
                delete options.headers['Content-Type'];
                options.body = this.setFormData(params);
            } else {
                options.body = JSON.stringify(params);
            }
        }

        route = `${url}${route}`;
        try {
            return fetch(route, options).then(res => {
                if (res.status === 403) {
                    localStorage.removeItem("appKey");
                }
                if (fileRes) {
                    return res.blob();
                } else {
                    return res.json();
                }
            });
        } catch (error) {
            console.error(error, "API ERR");
        }
    }
}

export default new Api();