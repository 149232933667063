import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import Pagination from "./pagination";

export default class MediaList extends React.Component {

    componentDidMount() {
        const { getDocuments } = this.props;
        getDocuments();
    }

    changeFilter = (name, value) => {
        const { changeFilter } = this.props;
        changeFilter(name, value);
    }

    changeTagsFilter = (keyCode) => {
        const { filters, changeFilter } = this.props;
        let value = this.refs.tags.value;
        let tags = JSON.parse(JSON.stringify(filters.tags));
        if (keyCode === 13 && !_.includes(filters.tags, value)) {
            tags.push(value);
            changeFilter("tags", tags);
            this.refs.tags.value = "";
        }
    }

    deleteTag = (tag) => {
        const { filters, changeFilter } = this.props;
        let tags = _.xor(JSON.parse(JSON.stringify(filters.tags)), [tag]);
        changeFilter("tags", tags);
    }

    renderList() {
        const { documents } = this.props;

        if (_.isEmpty(documents)) {
            return <p>Brak plików do wyświetlenia</p>;
        } else {
            return documents.map((doc, index) => {
                let tags = doc.tags.map((tag, tagIndex) => <span className="badge badge-secondary badge-pill mr-1" key={`${index}${tagIndex}`}>{tag}</span>)

                return (
                    <Link className="list-group-item d-flex flex-grow-5 justify-content-between align-items-center list-group-item-action" to={`/mediaDetails/${doc._id}`} key={index} title={doc.description}>
                        {doc.name}
                        <div>
                            {tags}
                        </div>
                    </Link>);
            });
        }
    }

    renderPagination = () => {
        const { offset, total, changeOffset, getDocuments, changePage, page } = this.props;
        return (
            <Pagination
                getDocuments={getDocuments}
                changeOffset={changeOffset}
                offset={offset}
                total={total}
                perPage={25}
                setPage={changePage}
                page={page}
            />
        )
    }

    renderSearchbar = () => {
        const { filters } = this.props;
        return (
            <div className="row mb-5 align-items-center">
                <div className="col-7">
                    <label>Tagi</label>
                    <input className="form-control" type="text" placeholder="Wpisz nazwę tagu i nacisnij Enter" onKeyDown={(e) => this.changeTagsFilter(e.keyCode)} ref="tags" />
                </div>
                <div className="col-3">
                    <label>Nazwa</label>
                    <input className="form-control" type="text" placeholder="Wyszukaj po nazwie" value={filters.name} onChange={(e) => this.changeFilter("name", e.target.value)} />
                </div>
                <div className="col-2 text-center btn-group">
                    <button className="btn btn-secondary mt-4" onClick={() => this.props.resetFilters()}>Usuń filtry</button>
                    <Link to="/mediaForm" className="btn btn-primary mt-4">Dodaj</Link>
                </div>
            </div>
        );
    }



    renderTags = () => {
        const { filters } = this.props;

        if (_.isArray(filters.tags)) {
            let tags = filters.tags.map((tag, index) => {
                return <span className="badge badge-secondary mr-1 with-pointer" key={`tag${index}`} title="Kliknij aby usunąć" onClick={() => this.deleteTag(tag)}>{tag}</span>
            });
            return (<div className="row mt-5">
                <div className="col-7">{tags}</div>
            </div>);
        } else {
            return <p>Brak tagów</p>
        }
    }

    render() {
        const list = this.renderList();
        const pagination = this.renderPagination();
        const searchbar = this.renderSearchbar();
        const tags = this.renderTags();

        return (
            <div className="m-5 text-center">
                <h3>Lista plików</h3>
                <hr />
                {tags}
                {searchbar}
                <ul className="list-group">
                    {list}
                </ul>
                {pagination}
            </div>
        );
    }
}