import React from 'react';
import { connect } from 'react-redux';
import { getDocuments, changeFilter, resetFilters, changeOffset, changePage } from "../actions/mediaList";
import MediaList from "../components/mediaList";

class MediaListContainer extends React.Component {

    render() {
        return <MediaList {...this.props} />;
    }
}

function mapStateToProps(store) {
    return {
        documents: store.mediaList.documents,
        filters: store.mediaList.filters,
        total: store.mediaList.total,
        page: store.mediaList.page,
        offset: store.mediaList.offset
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocuments: () => {
            dispatch(getDocuments());
        },
        changeFilter: (name, value) => {
            dispatch(changeFilter(name, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        changeOffset: (value) => {
            dispatch(changeOffset(value))
        },
        changePage: (value) => {
            dispatch(changePage(value))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaListContainer);