import React from 'react';
import AceEditor from "react-ace";
import _ from 'lodash';
import { Link } from "react-router-dom";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

export default class Form extends React.Component {

    componentDidMount() {
        const { getDocument, match } = this.props;
        if (_.get(match, "params.id")) {
            getDocument(_.get(match, "params.id"));
        }
    }

    renderTags = () => {
        const { document } = this.props;

        if (_.isArray(document.tags)) {
            return document.tags.map((tag, index) => {
                return <span className="badge badge-secondary mr-1" key={`tag${index}`}>{tag}</span>
            });
        } else {
            return <p>Brak tagów</p>
        }
    }

    deleteScript = () => {
        const { deleteScript, history } = this.props;
        document.getElementById("dismiss-button").click();
        deleteScript(history);
    }

    renderDeleteModal = () => {
        return (
            <div className="modal" id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Czy chcesz usunąć?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="dismiss-button" data-dismiss="modal">Zamknij</button>
                            <button type="button" className="btn btn-danger" onClick={() => { this.deleteScript() }}>Usuń</button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    copyCode = async () => {
        const { document } = this.props;
        if (_.get(navigator, "clipboard.writeText")) {
            await navigator.clipboard.writeText(document.code);
        }
    }

    render() {
        const { document } = this.props;

        if (!document) {
            return null;
        }
        const deleteModal = this.renderDeleteModal();
        const tags = this.renderTags();

        return (
            <div className="row p-5 text-center">
                {deleteModal}
                <div className="col-2 text-left">
                    <Link to="/" className="btn btn-secondary">Wstecz</Link>
                </div>
                <div className="col-8">
                    <h3>{document.name}</h3>
                </div>
                <div className="col-2 btn-group">
                    <Link to={`/form/${document._id}`} className="btn btn-primary">Edytuj</Link>
                    <button className="btn btn-info" onClick={() => this.copyCode()} >Kopiuj kod</button>
                    <button className="btn btn-danger" data-toggle="modal" data-target="#deleteModal">Usuń</button>
                </div>
                <div className="col-12 mb-5">
                    <hr />
                </div>
                <div className="col-6">
                    <label><b>Opis skryptu</b></label>
                    <p>{document.description}</p>
                </div>
                <div className="col-6">
                    <label><b>Kod skryptu</b></label>
                    <AceEditor
                        placeholder="Wpisz kod skryptu"
                        mode="javascript"
                        theme="monokai"
                        name="code"
                        onLoad={this.onLoad}
                        fontSize={14}
                        readOnly={true}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={document.code}
                        width="100%"
                        showLineNumbers={true}
                    />
                </div>
                <div className="col-12 mt-5">
                    <label><b>Tagi</b></label><br />
                    {tags}
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }

}